import React, { useContext, useEffect } from "react";
import { PageProps, graphql } from "gatsby";

import { Query } from "@graphql-types";

import NextOrPreviousWork from "@components/work/nextOrPreviousWork";

import SEO from "@components/shared/seo";
import { Wrapper, PageWrapper } from "./template.styles";
import WorkInnerHero from "@components/workInner/workInnerHero";
import { Blocks } from "@components/shared/blocks";
import { ThemeContext } from "@components/shared/themeProvider";
interface Props extends PageProps {
  data: Query;
  pageContext: any;
}

export default function WorkTemplate({ data, pageContext }: Props) {
  const work = data?.allSanityWork?.nodes?.[0];
  if (!work) return null;

  const { hero, backgroundColor, textColor } = work;
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme({ text: textColor!, background: backgroundColor! });
  }, []);

  return (
    <PageWrapper text={textColor} background={backgroundColor}>
      <SEO seoData={work.seo} slug={pageContext?.slug} />
      <WorkInnerHero data={hero} />
      <Wrapper>
        <Blocks data={work.columns as any[]} />
        <NextOrPreviousWork workSlug={work.slug?.current} />
      </Wrapper>
    </PageWrapper>
  );
}

export const query = graphql`
  query workQuery($slug: String) {
    allSanityWork(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        seo {
          ...sanitySeo
        }
        _id
        title
        backgroundColor
        textColor
        summary
        client
        slug {
          current
        }
        projectType {
          _id
          title
        }
        roles {
          slug {
            current
          }
          name
          _id

          pageContent {
            ... on SanityRelatedWork {
              _type
            }
            ... on SanityOtherServices {
              _type
            }
            ... on SanityImageTextColumn {
              _type
            }
            ... on SanityImageColumns {
              _type
            }
            ... on SanityImageBlock {
              _type
            }
          }
        }
        techUsed {
          slug {
            current
          }
          _id
          name
        }
        website
        team {
          _id
          name
          roles {
            title
          }
          image {
            asset {
              fluid(maxWidth: 2000) {
                src
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        hero {
          backgroundImage {
            ...sanityImageWithMeta
          }
          logo {
            asset {
              url
              fluid(maxWidth: 1000) {
                src
                ...GatsbySanityImageFluid
              }
            }
          }
          headerText {
            _rawColumnContent
          }
        }
        columns {
          ...sanityImageBlock
          ...sanityVideoBlock
          ...sanityTextAndCapabilities
          ...sanityImageTextColumn
          ...sanityImageColumns
          ...sanityScrollContainer
          ...sanityBackgroundImageWithText
          ...sanityTextBlocksAndCapabilities
          ...sanityColumnContent
          ...sanityRowContent
        }
      }
    }
  }
`;
